import {
  AppBar,
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material'
import React, { useCallback, useMemo } from 'react'
import { useWindowSize } from 'src/hooks/useWindowSize'
import useDisclosure from 'src/hooks/useDisclosure'
import { Menu } from '@mui/icons-material'
import { NavButton } from './styles'

import HeaderIcon from '../../assets/logo/logo-redondo.webp'

export const Header: React.FC = () => {
  const theme = useTheme()
  const { width } = useWindowSize()
  const { isOpen, onOpen, onClose } = useDisclosure(false)

  const navItems = useMemo(() => [
    {
      name: 'Início',
      path: 'inicio',
    },
    {
      name: 'Sobre Mim',
      path: 'sobre-mim',
    },
    {
      name: 'Áreas De Atuação',
      path: 'areas-de-atuacao',
    },
    {
      name: 'Artigos',
      path: 'artigos',
    },
    {
      name: 'Contatos',
      path: 'contatos',
    },
  ], [])

  const handleScrollToElement = useCallback((elementId: string) => {
    const element = document.getElementById(elementId)
    if (element == null) {
      console.warn('Elemento não encontrado')
      return
    }

    const offset = -80 // px
    const targetY = element.getBoundingClientRect().top + window.scrollY + offset

    window.scrollTo({ top: targetY, behavior: 'smooth' })
  }, [])

  return (
    <AppBar
      position="fixed"
      component="header"
      sx={{
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.text.secondary,
        top: 0,
        height: '80px',
        p: '0.5rem 1.2rem',

        [theme.breakpoints.up('sm')]: {
          p: '0.5rem 3rem',
        },
      }}
    >

      <Toolbar
        component="nav"
        sx={{
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '0 0',
        }}
      >
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            height: '100%',

            img: {
              height: '85%',
              mr: '1rem',
            },
          }}
        >
          <img src={HeaderIcon} alt="Ícone da minerva segurando uma balança, símbolo da justiça" />

          {/* <Divider
            orientation="vertical"
            sx={{
              height: '75%',
              width: '1px',
              m: '0.8rem',
              background: theme.palette.text.secondary,
            }}
          /> */}

          <Box sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          >
            <Typography
              component="h1"
              variant="h4"
              sx={{
                fontSize: '1.3rem',
                fontWeight: '600',
                letterSpacing: '0.04em',
                fontFamily: '"Cinzel", serif',
                background: 'linear-gradient(to right, #83673B 0%, #DBBE80 50%, #95733A 100%)',
                '-webkit-background-clip': 'text',
                '-webkit-text-fill-color': 'transparent',

                [theme.breakpoints.up('lg')]: {
                  fontSize: '1.6rem',
                },
              }}
            >
              Jaqueline Costal
            </Typography>
            <Typography
              component="h2"
              variant="h3"
              sx={{
                fontSize: '0.65rem',
                fontWeight: 600,
                fontFamily: '"Montserrat", sans-serif',
                background: 'linear-gradient(to right, #83673B 0%, #DBBE80 50%, #95733A 100%)',
                '-webkit-background-clip': 'text',
                '-webkit-text-fill-color': 'transparent',

                [theme.breakpoints.up('lg')]: {
                  fontSize: '0.8rem',
                },
              }}
            >
              ADVOCACIA PARA MULHERES
            </Typography>
          </Box>
        </Box>

        {width < theme.breakpoints.values.lg ? (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={onOpen}
          // sx={{ display: { sm: 'none' } }}
          >
            <Menu />
          </IconButton>
        ) : (
          <List
            sx={{
              display: 'flex',
              color: theme.palette.text.secondary,
            }}
          >
            {navItems.map((item) => (
              <ListItem key={item.path}>
                <NavButton onClick={() => handleScrollToElement(item.path)}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontFamily: '"Cinzel", serif',
                      fontSize: '1.1rem',
                    }}
                  >
                    {item.name}
                  </Typography>
                </NavButton>
              </ListItem>
            ))}
          </List>
        )}

        <Drawer
          anchor="right"
          open={isOpen}
          variant="temporary"
          onClose={onClose}
          ModalProps={{ keepMounted: true }}
          PaperProps={{
            sx: {
              backgroundColor: theme.palette.primary.dark,
            },
          }}
        >
          <Box sx={{ textAlign: 'center' }}>
            <List>
              {navItems.map((item) => (
                <ListItem key={item.path} onClick={onClose}>
                  <NavButton onClick={() => handleScrollToElement(item.path)}>
                    <Typography
                      variant="h6"
                      sx={{
                        fontFamily: '"Cinzel", serif',
                      }}
                    >
                      {item.name}
                    </Typography>
                  </NavButton>
                </ListItem>
              ))}
            </List>
          </Box>
        </Drawer>
      </Toolbar>
    </AppBar>
  )
}
