import { Box } from '@mui/material'
import React from 'react'
import { ParentFC } from 'src/types/ParentFC'
import { Header } from '../Header'
import { Footer } from '../Footer'
import { WhatsAppButton } from '../WhatsAppButton'

type PageProps = ParentFC
export const Page: React.FC<PageProps> = ({ children }) => {
  return (
    <Box>
      <Header />
      <Box sx={{ height: 80 }} />

      <Box component="main" flex={1}>
        {children}
      </Box>

      <Footer />

      <WhatsAppButton />
    </Box>
  )
}
