import { useState } from 'react'

interface IDisclosure {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  onToggle: () => void;
}

const useDisclosure = (initOpened: boolean): IDisclosure => {
  const [isOpen, setIsOpen] = useState(initOpened || false)

  function onOpen() {
    setIsOpen(true)
  }

  function onClose() {
    setIsOpen(false)
  }

  function onToggle() {
    setIsOpen(!isOpen)
  }

  return {
    isOpen,
    onOpen,
    onClose,
    onToggle,
  }
}

export default useDisclosure
