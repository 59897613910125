import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import Routes from 'src/routes'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { getMuiTheme } from './styles/themes/muiTheme'
import './styles/fonts/fonts.css'

const App: React.FC = () => {
  const theme = createTheme(getMuiTheme('light'))
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <CssBaseline />

        <Routes />

      </ThemeProvider>
    </Router>
  )
}

export default App
