import React from 'react'
import { Page } from 'src/components/Page'
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from '@mui/material'
import { PostsCarrousel } from 'src/components/PostsCarrousel'
import { Section } from '../../components/Section'

import MainImage from '../../assets/mulheres-de-braco-cruzado.webp'
import ProfileImage from '../../assets/fotos/foto-na-escada.webp'

import DireitoPenal from '../../assets/direito-penal-algemas-3cm.webp'
import DireitoDasFamilias from '../../assets/direito-das-familias-3cm.webp'
import DireitoDoTrabalho from '../../assets/direito-do-trabalho-chapeu-de-protecao-3cm.webp'
import DireitoCivil from '../../assets/direito-civil-3cm.webp'

export const HomePage: React.FC = () => {
  const theme = useTheme()

  return (
    <Page>
      <Section id="inicio" backgroundColor="#FFF" sx={{ color: theme.palette.secondary.main }}>
        <Box
          sx={{
            p: '3rem 0',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            flexWrap: 'wrap',

            gap: '4rem',
          }}
        >
          <Box
            component="img"
            src={MainImage}
            alt="Mulheres unidas de braço cruzado"
            loading="eager"
            sx={{
              width: '95%',
              height: 'auto',

              [theme.breakpoints.up('sm')]: {
                flex: '1 0 350px',
                minWidth: '350px',
                maxWidth: '500px',
              },
            }}
          />
          <Typography
            component="h3"
            variant="h4"
            textAlign="center"
            sx={{
              width: '95%',
              fontSize: '1.9rem',

              [theme.breakpoints.up('sm')]: {
                flex: '1 0 350px',
                fontSize: '2.125rem',
              },
            }}
          >
            Empoderando mulheres: uma causa, uma missão, um compromisso.
          </Typography>
        </Box>
      </Section>

      <Section id="sobre-mim">
        <Box sx={{
          p: '3rem 0',
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'space-evenly',
          flexWrap: 'wrap',
          gap: '4rem',
        }}
        >
          <Box
            sx={{
              textAlign: 'justify',
              width: '95%',

              [theme.breakpoints.up('sm')]: {
                flex: '1 0 350px',
              },
            }}
          >
            <Typography variant="h4">
              Sobre mim
            </Typography>

            <Divider sx={{
              mt: '1rem',
              height: '2px',
              width: '6rem',
              backgroundColor: theme.palette.secondary.dark,
            }}
            />

            <Typography mt="2rem">
              Dedicada à excelência jurídica e comprometida com a defesa
              incansável dos direitos das mulheres, minha missão é proporcionar
              um serviço jurídico excepcional, pautado pela integridade,
              ética e compromisso com a justiça.
            </Typography>
            <Typography mt="1rem">
              Formada em direito pela Faculdade de Direito da Universidade de
              São Paulo (USP), com graduação em biologia e mestrado em ciências
              pela mesma instituição, além de pós-graduanda em direito penal e
              criminologia pela Pontifícia Universidade Católica do Rio Grande
              do Sul (PUC-RS), trago uma bagagem acadêmica e prática
              interdisciplinar que enriquece as minhas práticas e estratégias
              jurídicas.
            </Typography>
            <Typography mt="1rem">
              Minha atuação abrange diversas áreas do direito, incluindo o
              direito criminal, civil, das famílias e do trabalho, com foco no
              direito das mulheres, oferecendo suporte especializado em
              questões que afetam diretamente o universo feminino.
            </Typography>
            <Typography mt="1rem">
              Com uma abordagem cuidadosa, empática e personalizada para cada
              caso, estou pronta para oferecer orientação legal e representação
              eficaz em todas as etapas do processo. Valorizo profundamente a
              confiança e o relacionamento com as minhas clientes, buscando
              sempre superar expectativas e alcançar resultados satisfatórios.
            </Typography>
          </Box>

          <Box
            component="img"
            src={ProfileImage}
            alt="Foto de perfil da Jaqueline Costal"
            loading="lazy"
            sx={{
              width: '80%',
              height: 'auto',

              [theme.breakpoints.up('sm')]: {
                flex: '1 0 350px',
                minWidth: '350px',
                maxWidth: '400px',
              },
            }}
          />

        </Box>
      </Section>
      <Section
        id="areas-de-atuacao"
        backgroundColor="#FFF"
        sx={{ color: theme.palette.secondary.main }}
      >
        <Box
          sx={{
            p: '3rem 0',
            textAlign: 'justify',

            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box sx={{ width: '95%' }}>
            <Typography variant="h4">
              Áreas de Atuação
            </Typography>

            <Divider sx={{
              mt: '1rem',
              height: '2px',
              width: '6rem',
              backgroundColor: theme.palette.secondary.main,
            }}
            />

            <Typography mt="2rem">
              Advocacia dedicada a questões que evidenciam desigualdades de
              gênero, e que, por isso, requerem uma abordagem empática,
              comprometida, acolhedora e humanizada.
            </Typography>
            <Typography mt="1rem">
              Atuo em diversas áreas do direito, oferecendo orientação e
              representação legal para mulheres, independentemente de estarem
              enfrentando situações de violência, e também para pessoas
              LGBTQIAP+. Estou aqui para fornecer apoio tanto em consultas
              preventivas quanto em casos litigiosos, garantindo que todas
              tenham acesso à justiça e à proteção dos seus direitos.
            </Typography>
          </Box>

          <List sx={{
            width: '95%',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-evenly',

            li: {
              mt: '3rem',
              flex: '0 1 400px',
              gap: '2rem',

              img: {
                height: '3rem',
              },

              span: {
                fontSize: '1.25rem',
                fontFamily: '"Cinzel", serif',
              },
            },
          }}
          >
            <ListItem>
              <ListItemIcon>
                <img src={DireitoPenal} alt="Ícone com duas algemas e um malhete de Juiz à frente" />
              </ListItemIcon>
              <ListItemText primary="Direito Penal" />
            </ListItem>

            <ListItem>
              <ListItemIcon>
                <img src={DireitoDasFamilias} alt="Ícone de duas pessoas arredondadas com um filho dentro de um coração" />
              </ListItemIcon>
              <ListItemText primary="Direito Das Famílias" />
            </ListItem>

            <ListItem>
              <ListItemIcon>
                <img src={DireitoDoTrabalho} alt="Ícone de um capacete de proteção de com um malhete de Juiz à frente" />
              </ListItemIcon>
              <ListItemText primary="Direito Do Trabalho" />
            </ListItem>

            <ListItem>
              <ListItemIcon>
                <img src={DireitoCivil} alt="Algemas com um malhete de Juiz à frente" />
              </ListItemIcon>
              <ListItemText primary="Direito Civil" />
            </ListItem>
          </List>
        </Box>
      </Section>

      <Section id="artigos">
        <Box sx={{
          p: '3rem 0',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
        >
          <Typography variant="h4">
            Artigos
          </Typography>

          <Divider sx={{
            mt: '1rem',
            height: '2px',
            width: '6rem',
            backgroundColor: theme.palette.secondary.dark,
          }}
          />

          <PostsCarrousel />
        </Box>
      </Section>
    </Page>
  )
}
