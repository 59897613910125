import { Box, Link, Typography } from '@mui/material'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Page } from 'src/components/Page'
import { Section } from 'src/components/Section'

export const NotFound: React.FC = () => {
  return (
    <Page>
      <Section
        id="pagina-nao-contrada"
        sx={{ height: 720 }}
      >
        <Box sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: '5rem',
        }}
        >
          <h3>
            Página não encontrada!
          </h3>
          <Typography variant="h5">
            Sinto muito, não encontramos o que você procurava!
          </Typography>

          <Link
            component={RouterLink}
            to="/"
            underline="none"
            aria-label="Voltar para a página inicial"
          >
            <Typography variant="h5">Voltar para a página inicial</Typography>
          </Link>
        </Box>
      </Section>
    </Page>
  )
}
