import { PaletteOptions } from '@mui/material'
import {
  common,
  blueGrey,
  green,
  lightGreen,
  red,
} from '@mui/material/colors'

export const dark: PaletteOptions = {
  primary: {
    light: blueGrey[600],
    main: blueGrey[800],
    dark: blueGrey[900],
    contrastText: '#fff',
  },
  secondary: {
    light: lightGreen.A200,
    main: lightGreen.A400,
    dark: lightGreen.A700,
    contrastText: '#000',
  },
  background: {
    default: common.black,
  },
  error: {
    main: red[900],
  },
  success: {
    main: green[700],
  },
}
