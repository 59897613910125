import {
  PaletteMode, ThemeOptions,
} from '@mui/material'
import { dark } from './dark'
import { light } from './light'

export const getMuiTheme = (mode: PaletteMode): ThemeOptions => ({
  typography: {
    allVariants: {
      fontFamily: '"Arapey", serif',
      fontWeight: 400,
      fontStyle: 'normal',
    },
    body1: {
      fontSize: '1rem',

      '@media (min-width:600px)': {
        fontSize: '1.2rem',
      },
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          height: '100%',
          scrollBehavior: 'smooth',
        },
        body: {
          height: '100%',
        },
        '#root': {
          height: '100%',
        },
        fieldset: {
          border: 'none',
        },

        '*': {
          fontFamily: '"Arapey", serif',
          fontWeight: 400,
          fontStyle: 'normal',
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        fontFamily: '"Arapey", serif',
        fontWeight: 400,
        fontStyle: 'normal',
      },
    },
  },
  palette: {
    mode, // dark is the only mode for now
    ...(mode === 'dark'
      ? dark
      : light),
  },
})
