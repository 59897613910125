import { Button, styled } from '@mui/material'
import { Link } from 'react-router-dom'

export const HomeButton = styled(Link)(({ theme }) => ({
  color: theme.palette.common.white,
  textDecoration: 'none',
}))

export const NavButton = styled(Button)(({ theme }) => ({
  color: theme.palette.common.white,
  textWrap: 'nowrap',
  textTransform: 'none',
}))
