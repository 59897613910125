import {
  Box, Container, SxProps, Theme,
} from '@mui/material'
import React from 'react'
import { ParentFC } from 'src/types/ParentFC'

interface SectionProps extends ParentFC {
  id: string;
  backgroundColor?: string;
  sx?: SxProps<Theme>
}

export const Section: React.FC<SectionProps> = ({
  id, backgroundColor, children, sx,
}) => {
  return (
    <Box
      component="section"
      id={id}
      sx={{
        // minHeight: 720,
        backgroundColor: (theme) => backgroundColor || theme.palette.background.paper,
        color: (theme) => theme.palette.secondary.dark,
        h4: {
          fontFamily: '"Cinzel", serif',
          fontSize: '1.8rem',
        },
        ...sx,
      }}
    >
      <Container maxWidth="lg" sx={{ height: '100%' }}>
        {children}
      </Container>
    </Box>
  )
}
