import { WhatsApp } from '@mui/icons-material'
import { Fab, Link } from '@mui/material'
import React, { useRef } from 'react'

export const WhatsAppButton: React.FC = () => {
  const buttonRef = useRef<HTMLAnchorElement>(null)

  return (
    <Fab
      ref={buttonRef}
      LinkComponent={Link}
      target="_blank"
      href="https://wa.me/5511965998881"
      aria-label="Envie uma mensagem no whatsapp para a Jaqueline"
      sx={{
        position: 'fixed',
        right: '1rem',
        bottom: '1rem',
      }}
      size="large"
      color="success"
    >
      <WhatsApp sx={{ fontSize: '2rem' }} />
    </Fab>
  )
}
