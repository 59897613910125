import { PaletteOptions } from '@mui/material'
import {
  common, red,
} from '@mui/material/colors'

export const light: PaletteOptions = {
  primary: {
    light: red[800],
    main: red[900],
    dark: '#570909',
    contrastText: common.white,
  },
  secondary: {
    light: '#C57545',
    main: '#81472D',
    dark: '#4F2C1C',
    contrastText: common.black,
  },
  text: {
    primary: '#81472D',
    secondary: '#FFF0EB',
  },
  background: {
    default: '#FFF',
    paper: '#FFF0EB',
    paper2: '#E5C9B2',
  },
}
