import { Instagram, LinkedIn } from '@mui/icons-material'
import {
  Box, Container, Divider, Link, List, ListItem, Typography,
} from '@mui/material'
import React from 'react'

import BloggerIcon from '../../assets/blogger-icon.svg'

export const Footer: React.FC = () => {
  return (
    <Box
      component="footer"
      id="contatos"
      sx={{
        // backgroundColor: (theme) => theme.palette.primary.main,
        '*': {
          color: (theme) => theme.palette.secondary.main,
        },
        hr: {
          backgroundColor: (theme) => theme.palette.secondary.dark,
        },
      }}
    >
      <Container
        maxWidth="lg"
        sx={(theme) => ({
          p: '3rem',
          height: '100%',
          display: 'flex',
          alignItems: 'flex-start',
          flexWrap: 'wrap',

          justifyContent: 'flex-start',
          [theme.breakpoints.up('sm')]: {
            justifyContent: 'space-evenly',
          },

          section: {
            pt: '2rem',

            h4: {
              fontFamily: '"Cinzel", serif',
              fontSize: '1.8rem',
            },
          },
        })}
      >
        <section>
          <Typography variant="h4">
            Entre em contato
          </Typography>

          <Divider sx={{
            mt: '1rem',
            height: '2px',
            width: '6rem',
          }}
          />

          <List sx={{ li: { pl: 0, pb: 0 } }}>
            <ListItem>
              <Link
                target="_blank"
                href="mailto:costal.jaqueline.adv@gmail.com"
                underline="hover"
                aria-label="Envie um e-mail para a Jaqueline"
              >
                <Typography component="span" variant="h6">
                  costal.jaqueline.adv@gmail.com
                </Typography>
              </Link>
            </ListItem>

            <ListItem>
              <Link
                target="_blank"
                href="https://wa.me/5511965998881"
                underline="hover"
                aria-label="Envie uma mensagem no whatsapp para a Jaqueline"
              >
                <Typography component="span" variant="h6">
                  +55 (11) 96599-8881
                </Typography>
              </Link>
            </ListItem>
          </List>
        </section>

        <section>
          <Typography variant="h4">
            Outras redes
          </Typography>

          <Divider sx={{
            mt: '1rem',
            height: '2px',
            width: '6rem',
          }}
          />

          <List sx={{
            display: 'flex',
            li: { pl: 0, width: 'unset' },
            img: {
              height: '1.6rem',
              filter: 'invert(29%) sepia(8%) saturate(4423%) hue-rotate(334deg) brightness(96%) contrast(82%)',
            },
            svg: {
              fontSize: '2rem',
            },
          }}
          >
            <ListItem>
              <Link
                target="_blank"
                href="https://www.linkedin.com/in/jaqueline-costal/"
                underline="none"
                aria-label="Acesse o perfil do Linkedin da Jaqueline"
              >
                <LinkedIn />
              </Link>
            </ListItem>

            <ListItem>
              <Link
                target="_blank"
                href="https://vozesfemininasdireitosdasmulheres.blogspot.com"
                underline="none"
                aria-label="Acesse o perfil do Blogger da Jaqueline"
              >
                <img loading="lazy" src={BloggerIcon} alt="Ícone do blogger" />
              </Link>
            </ListItem>

            <ListItem>
              <Link
                target="_blank"
                href="https://www.instagram.com/jaquelinecostaladv"
                underline="none"
                aria-label="Acesse o perfil do Instagram da Jaqueline"
              >
                <Instagram />
              </Link>
            </ListItem>
          </List>
        </section>
      </Container>

      <Box sx={{ height: '60px', background: (theme) => theme.palette.primary.dark }} />
    </Box>
  )
}
