/* eslint-disable import/no-duplicates */
import {
  Box,
  Card, CardActionArea, CardContent, CardMedia, Fab, List, ListItem, Tooltip, Typography,
  useTheme,
} from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import { format, parseISO } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { NavigateBeforeRounded, NavigateNextRounded } from '@mui/icons-material'

interface IPost {
  id: string;
  url: string;
  title: string;
  image: string;
  imageTextAlt?: string;
  published: string;
}

interface IOriginalImages {
  url: string;
}

interface IOriginalPosts {
  id: string;
  url: string;
  title: string;
  images: IOriginalImages[];
  labels: string[]
  published: string;
}

interface IBloggerResponse {
  items: IOriginalPosts[];
}

export const PostsCarrousel: React.FC = () => {
  const theme = useTheme()
  const [posts, setPosts] = useState<IPost[]>([])
  const listRef = useRef<HTMLUListElement>(null)
  const [leftButtonDisabled, setLeftButtonDisabled] = useState(true)
  const [rightButtonDisabled, setRightButtonDisabled] = useState(false)

  useEffect(() => {
    const fetchPosts = async () => {
      const response = await axios.get<IBloggerResponse>('https://www.googleapis.com/blogger/v3/blogs/4271515579970097951/posts', {
        params: {
          key: 'AIzaSyAGhqeoUHzoU_UpfPoQBvim6GzyGPaCxEI',
          orderBy: 'published',
          sortOption: 'descending',
          fetchImages: 'true',
          fetchBodies: 'false',
          view: 'READER',
        },
      })

      const postsFetched = response.data.items.map((originalPost) => {
        const datePublished = parseISO(originalPost.published)
        return {
          id: originalPost.id,
          title: originalPost.title,
          image: originalPost.images[0].url,
          url: originalPost.url,
          imageTextAlt: `Imagem do blog: ${originalPost.title}`,
          published: `${format(datePublished, 'dd \'de\' MMMM \'de\' yyyy', { locale: ptBR })}`,
        } as IPost
      })

      setPosts(postsFetched)
    }

    fetchPosts()
  }, [])

  const checkScroll = () => {
    if (listRef.current) {
      const currentScroll = listRef.current.scrollLeft
      const scrollLength = listRef.current.scrollWidth - listRef.current.clientWidth
      if (currentScroll === 0) {
        setLeftButtonDisabled(true)
        setRightButtonDisabled(false)
      } else if (currentScroll === scrollLength) {
        setRightButtonDisabled(true)
        setLeftButtonDisabled(false)
      } else {
        setRightButtonDisabled(false)
        setLeftButtonDisabled(false)
      }
    }
  }

  const handleScrollLeft = () => {
    if (listRef.current) {
      listRef.current.scrollBy({
        left: -300,
        behavior: 'smooth',
      })
    }
  }

  const handleScrollRight = () => {
    if (listRef.current) {
      listRef.current.scrollBy({
        left: +300,
        behavior: 'smooth',
      })
    }
  }

  return (
    <Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
      <Fab
        size="medium"
        onClick={handleScrollLeft}
        disabled={leftButtonDisabled}
        sx={{
          display: 'none',

          [theme.breakpoints.up('lg')]: {
            display: 'inline-flex',
            mr: '2rem',
          },
        }}
      >
        <NavigateBeforeRounded />
      </Fab>
      <List
        id="articles-list"
        ref={listRef}
        onScroll={checkScroll}
        sx={{
          mt: '2rem',
          display: 'flex',
          // width: '100%',
          flex: 1,
          gap: 0.5,
          overflow: 'auto',
          scrollSnapType: 'x mandatory',
          '& > *': {
            scrollSnapAlign: 'center',
          },
          '::-webkit-scrollbar': { display: 'none' },
        }}
      >
        {posts.map((post) => (
          <ListItem key={post.id} sx={{ flex: '0 1 250px' }}>
            <Card
              sx={{
                width: 300,
                backgroundColor: theme.palette.background.default,
                // borderRadius: '10px',
                boxShadow: `
                0px 2px 4px -2px rgba(0,0,0,0.2),
                0px 8px 12px -2px rgba(0,0,0,0.14),
                0px 0px 0px 0px rgba(0,0,0,0.12)
              `,
              }}
            >
              <CardActionArea LinkComponent="a" href={post.url} target="_blank">
                <CardMedia
                  component="img"
                  loading="lazy"
                  sx={{ height: 220 }}
                  src={post.image}
                  alt={post.imageTextAlt}
                />

                <CardContent sx={{ height: '10rem' }}>
                  <Tooltip title={post.title} arrow>
                    <Typography
                      gutterBottom
                      variant="h6"
                      component="p"
                      sx={{
                        height: '100%',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: 4,
                        lineClamp: 4,
                        WebkitBoxOrient: 'vertical',
                        m: 0,
                      }}
                    >
                      {post.title}
                    </Typography>
                  </Tooltip>
                </CardContent>
                <CardContent>
                  <Typography>
                    {post.published}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </ListItem>
        ))}
      </List>

      <Fab
        size="medium"
        onClick={handleScrollRight}
        disabled={rightButtonDisabled}
        sx={{
          display: 'none',

          [theme.breakpoints.up('lg')]: {
            display: 'inline-flex',
            ml: '2rem',
          },
        }}
      >
        <NavigateNextRounded />
      </Fab>
    </Box>
  )
}
