import React from 'react'
import { Route, Routes as RouterDOMElement } from 'react-router-dom'
import { HomePage } from 'src/pages/HomePage'
import { NotFound } from 'src/pages/NotFound'

const Routes: React.FC = () => (
  <RouterDOMElement>
    <Route path="/" element={<HomePage />} />
    <Route path="/*" element={<NotFound />} />
  </RouterDOMElement>
)

export default Routes
